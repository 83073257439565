import React from 'react'
import Layout from '../components/layouts/landing'
import Heading from '../components/Heading'
import Text from '../components/Text'
import Icon from '../components/Icon'
import IconImage from '../images/icon.svg'

const IndexPage = () => {
  const Footer = <div css={{ padding: '2rem 0' }}>
    <Text size='small'>
      <strong>allUP Labs</strong>
      <br />
      8605 Santa Monica Blvd
      <br />
      PMB 27963
      <br />
      West Hollywood, California 90069-4109 US
    </Text>
    <div css={{ textAlign: 'center', fontWeight: 600, color: '#7069FC' }}>
      <a css={{ color: '#7069FC', textDecoration: 'none' }} href="https://wrkvbs.com/terms-and-conditions">Terms of Service</a> | <a css={{ color: '#7069FC', textDecoration: 'none' }} href="https://wrkvbs.com/privacy">Privacy Policy</a>
    </div>

    <div css={{ textAlign: 'center', padding: '0.2rem 0' }}><a css={{ fontWeight: 600, color: '#7069FC', textDecoration: 'none' }} href="mailto:support@wrkvbs.com">Get support</a></div>
  </div >

  return (
    <Layout title="wrkvbs" footer={Footer}>
      <div css={{ width: '100%', textAlign: 'center' }}>
        <Icon css={{ margin: '0 auto' }}>
          <IconImage />
        </Icon>
      </div>
      <Heading css={{ marginTop: 35, width: '100%' }}>You&apos;re more than your resume.</Heading>
      <Text>With your WRKVBS profile the world can finally see the real you through the people who know you best</Text>
    </Layout>
  )
}

export default IndexPage
